import { Colors } from 'styles/colors';

import type { ThemeOptions } from '@mui/material';

export const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      sx: {
        borderRadius: 2,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      sx: {
        background: '#FFFFFF',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      sx: {
        '.MuiDialog-paper': {
          background: '#FFFFFF',
          borderRadius: 4,
        },
      },
    },
  },
  MuiDialogActions: {
    defaultProps: {
      sx: {
        padding: '0 1rem 1rem',
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
      sx: {
        '&.Mui-selected': {
          backgroundColor: Colors.eliceBlue0,
        },
        '&.Mui-selected:hover': {
          backgroundColor: Colors.eliceBlue2,
        },
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
};
