/* eslint-disable @typescript-eslint/naming-convention */
//-ref=https://zeroheight.com/28e7b9d96/p/25a3d8-color/b/281ad3

export enum Colors {
  red0 = '#ffebee',
  red1 = '#ffcdd2',
  red2 = '#ef9a9a',
  red3 = '#e57373',
  red4 = '#ef5350',
  red5 = '#f44336',
  red6 = '#e53935',
  red7 = '#d32f2f',
  red8 = '#c62828',
  red9 = '#b71c1c',

  pink0 = '#fce4ec',
  pink1 = '#f8bbd0',
  pink2 = '#f48fb1',
  pink3 = '#f06292',
  pink4 = '#ec407a',
  pink5 = '#e91e63',
  pink6 = '#d81b60',
  pink7 = '#c2185b',
  pink8 = '#ad1457',
  pink9 = '#880e4f',

  purple0 = '#f3e5f5',
  purple1 = '#e1bee7',
  purple2 = '#ce93d8',
  purple3 = '#ba68c8',
  purple4 = '#ab47bc',
  purple5 = '#9c27b0',
  purple6 = '#8e24aa',
  purple7 = '#7b1fa2',
  purple8 = '#6a1b9a',
  purple9 = '#4a148c',

  deepPurple0 = '#ede7f6',
  deepPurple1 = '#d1c4e9',
  deepPurple2 = '#b39ddb',
  deepPurple3 = '#9575cd',
  deepPurple4 = '#7e57c2',
  deepPurple5 = '#673ab7',
  deepPurple6 = '#5e35b1',
  deepPurple7 = '#512da8',
  deepPurple8 = '#4527a0',
  deepPurple9 = '#311b92',

  indigo0 = '#e8eaf6',
  indigo1 = '#c5cae9',
  indigo2 = '#9fa8da',
  indigo3 = '#7986cb',
  indigo4 = '#5c6bc0',
  indigo5 = '#3f51b5',
  indigo6 = '#3949ab',
  indigo7 = '#303f9f',
  indigo8 = '#283593',
  indigo9 = '#1a237e',

  blue0 = '#e3f2fd',
  blue1 = '#bbdefb',
  blue2 = '#90caf9',
  blue3 = '#64b5f6',
  blue4 = '#42a5f5',
  blue5 = '#2196f3',
  blue6 = '#1e88e5',
  blue7 = '#1976d2',
  blue8 = '#1565c0',
  blue9 = '#0d47a1',

  lightBlue0 = '#e1f5fe',
  lightBlue1 = '#b3e5fc',
  lightBlue2 = '#81d4fa',
  lightBlue3 = '#4fc3f7',
  lightBlue4 = '#29b6f6',
  lightBlue5 = '#03a9f4',
  lightBlue6 = '#039be5',
  lightBlue7 = '#0288d1',
  lightBlue8 = '#0277bd',
  lightBlue9 = '#01579b',

  cyan0 = '#e0f7fa',
  cyan1 = '#b2ebf2',
  cyan2 = '#80deea',
  cyan3 = '#4dd0e1',
  cyan4 = '#26c6da',
  cyan5 = '#00bcd4',
  cyan6 = '#00acc1',
  cyan7 = '#0097a7',
  cyan8 = '#00838f',
  cyan9 = '#006064',

  teal0 = '#e0f2f1',
  teal1 = '#b2dfdb',
  teal2 = '#80cbc4',
  teal3 = '#4db6ac',
  teal4 = '#26a69a',
  teal5 = '#009688',
  teal6 = '#00897b',
  teal7 = '#00796b',
  teal8 = '#00695c',
  teal9 = '#004d40',

  green0 = '#e8f5e9',
  green1 = '#c8e6c9',
  green2 = '#a5d6a7',
  green3 = '#81c784',
  green4 = '#66bb6a',
  green5 = '#4caf50',
  green6 = '#43a047',
  green7 = '#388e3c',
  green8 = '#2e7d32',
  green9 = '#1b5e20',

  lightGreen0 = '#f1f8e9',
  lightGreen1 = '#dcedc8',
  lightGreen2 = '#c5e1a5',
  lightGreen3 = '#aed581',
  lightGreen4 = '#9ccc65',
  lightGreen5 = '#8bc34a',
  lightGreen6 = '#7cb342',
  lightGreen7 = '#689f38',
  lightGreen8 = '#558b2f',
  lightGreen9 = '#33691e',

  lime0 = '#f9fbe7',
  lime1 = '#f0f4c3',
  lime2 = '#e6ee9c',
  lime3 = '#dce775',
  lime4 = '#d4e157',
  lime5 = '#cddc39',
  lime6 = '#c0ca33',
  lime7 = '#afb42b',
  lime8 = '#9e9d24',
  lime9 = '#827717',

  yellow0 = '#fffde7',
  yellow1 = '#fff9c4',
  yellow2 = '#fff59d',
  yellow3 = '#fff176',
  yellow4 = '#ffee58',
  yellow5 = '#ffeb3b',
  yellow6 = '#fdd835',
  yellow7 = '#fbc02d',
  yellow8 = '#f9a825',
  yellow9 = '#f57f17',

  amber0 = '#fff8e1',
  amber1 = '#ffecb3',
  amber2 = '#ffe082',
  amber3 = '#ffd54f',
  amber4 = '#ffca28',
  amber5 = '#ffc107',
  amber6 = '#ffb300',
  amber7 = '#ffa000',
  amber8 = '#ff8f00',
  amber9 = '#ff6f00',

  orange0 = '#fff3e0',
  orange1 = '#ffe0b2',
  orange2 = '#ffcc80',
  orange3 = '#ffb74d',
  orange4 = '#ffa726',
  orange5 = '#ff9800',
  orange6 = '#fb8c00',
  orange7 = '#f57c00',
  orange8 = '#ef6c00',
  orange9 = '#e65100',

  deepOrange0 = '#fbe9e7',
  deepOrange1 = '#ffccbc',
  deepOrange2 = '#ffab91',
  deepOrange3 = '#ff8a65',
  deepOrange4 = '#ff7043',
  deepOrange5 = '#ff5722',
  deepOrange6 = '#f4511e',
  deepOrange7 = '#e64a19',
  deepOrange8 = '#d84315',
  deepOrange9 = '#bf360c',

  brown0 = '#efebe9',
  brown1 = '#d7ccc8',
  brown2 = '#bcaaa4',
  brown3 = '#a1887f',
  brown4 = '#8d6e63',
  brown5 = '#795548',
  brown6 = '#6d4c41',
  brown7 = '#5d4037',
  brown8 = '#4e342e',
  brown9 = '#3e2723',

  grey0 = '#fafafa',
  grey1 = '#f5f5f5',
  grey2 = '#eeeeee',
  grey3 = '#e0e0e0',
  grey4 = '#bdbdbd',
  grey5 = '#9e9e9e',
  grey6 = '#757575',
  grey7 = '#616161',
  grey8 = '#424242',
  grey9 = '#212121',

  blueGray0 = '#fafbfb',
  blueGray1 = '#eeeff1',
  blueGray2 = '#e3e5e8',
  blueGray3 = '#cdd1d6',
  blueGray4 = '#bec4cd',
  blueGray5 = '#8d97a5',
  blueGray6 = '#6e7987',
  blueGray7 = '#4b5562',
  blueGray8 = '#343e4b',
  blueGray9 = '#191f28',

  elicePurple0 = '#f6f4fe',
  elicePurple1 = '#eeeafe',
  elicePurple2 = '#ded5fe',
  elicePurple3 = '#c4b7fe',
  elicePurple4 = '#ab9dfe',
  elicePurple5 = '#9986fe',
  elicePurple6 = '#8567fe',
  elicePurple7 = '#7353ea',
  elicePurple8 = '#613dd6',
  elicePurple9 = '#5a2ecc',

  eliceBlue0 = '#e0e7ff',
  eliceBlue1 = '#cad4ff',
  eliceBlue2 = '#a2b3ff',
  eliceBlue3 = '#7991ff',
  eliceBlue4 = '#5a71ff',
  eliceBlue5 = '#4253fd',
  eliceBlue6 = '#2e39ed',
  eliceBlue7 = '#0d1ae6',
  eliceBlue8 = '#0001cc',
  eliceBlue9 = '#0000b3',

  eliceNavy0 = '#e7e9f6',
  eliceNavy1 = '#c3c7e9',
  eliceNavy2 = '#9ba3d9',
  eliceNavy3 = '#737fca',
  eliceNavy4 = '#5563bf',
  eliceNavy5 = '#3548b4',
  eliceNavy6 = '#3040aa',
  eliceNavy7 = '#1d2c92',
  eliceNavy8 = '#0a197e',
  eliceNavy9 = '#000072',

  white = '#ffffff',
  black = '#000000',
}
