// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

import { sentry } from './configs';

Sentry.init({
  dsn: sentry.dsn,
  tracesSampleRate: 0.2,
});
