import React from 'react';
import { useEffect, useState } from 'react';

export enum MediaWidth {
  Laptop = 1280,
  Tablet = 768,
  Mobile = 576,
}

export const BreakPoints = Object.freeze({
  laptopOrLarger: `only screen and (min-width: ${MediaWidth.Laptop}px)`,
  laptopOrSmaller: `only screen and (max-width: ${MediaWidth.Laptop}px)`,
  tabletOrLarger: `only screen and (min-width: ${MediaWidth.Tablet}px)`,
  tabletOrSmaller: `only screen and (max-width: ${MediaWidth.Tablet}px)`,
  mobileOrLarger: `only screen and (min-width: ${MediaWidth.Mobile}px)`,
  mobileOrSmaller: `only screen and (max-width: ${MediaWidth.Mobile}px)`,
});

/**
 * React hook to check when a valid media-query-string changes
 * (i.e. when the media query test starts or stops evaluating to true)
 *
 * @param {string} mediaQueryString valid media query string. E.g.: `(min-width: 768px)`
 * @returns {boolean} media-query matches value
 */
function useMediaQuery(mediaQueryString: string) {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null);

  useEffect(() => {
    const setMediaMatchHandler = (e: any) => setQueryMatch(e.matches);

    const mediaQueryList = window.matchMedia(mediaQueryString);

    setMediaMatchHandler(mediaQueryList);

    if (mediaQueryList?.addEventListener) {
      mediaQueryList.addEventListener('change', setMediaMatchHandler);
    }

    return () => {
      if (mediaQueryList?.removeEventListener) {
        mediaQueryList.removeEventListener('change', setMediaMatchHandler);
      }
    };
  }, [mediaQueryString]);

  return queryMatch;
}

export default useMediaQuery;

const MediaQueryContext = React.createContext<any>(undefined);
type MediaQueryProviderProps = { children: React.ReactNode };

function MediaQueryProvider({ children }: MediaQueryProviderProps) {
  const laptopOrSmaller = useMediaQuery(BreakPoints.laptopOrSmaller);
  const tabletOrSmaller = useMediaQuery(BreakPoints.tabletOrSmaller);
  const mobileOrSmaller = useMediaQuery(BreakPoints.mobileOrSmaller);

  return (
    <MediaQueryContext.Provider
      value={{
        laptopOrSmaller,
        tabletOrSmaller,
        mobileOrSmaller,
      }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
}

interface UseMediaQueryContextValue {
  laptopOrSmaller: boolean;
  tabletOrSmaller: boolean;
  mobileOrSmaller: boolean;
}

function useMediaQueryContext() {
  const context =
    React.useContext<UseMediaQueryContextValue>(MediaQueryContext);

  if (context === undefined) {
    throw new Error(
      'useMediaQueryContext must be used within a MediaQueryProvider'
    );
  }

  return context;
}

export { MediaQueryProvider, useMediaQueryContext };
