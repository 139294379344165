/* eslint-disable @typescript-eslint/no-unused-expressions */
export const GTMPageView = (url: URL) => {
  interface PageEventProps {
    event: string;
    page: URL;
  }

  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };

  window &&
    (window as any).dataLayer &&
    (window as any).dataLayer.push(pageEvent);
  return pageEvent;
};
