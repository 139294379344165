const eliceApp = {
  webUrl: process.env.NEXT_PUBLIC_ELICE_APP_SCHOOL_URL || '',
  landingUrl: process.env.NEXT_PUBLIC_ELICE_APP_LANDING_URL || '',
  accountsUrl: process.env.NEXT_PUBLIC_ELICE_APP_ACCOUNTS_URL || '',
  helpcenterUrl: process.env.NEXT_PUBLIC_ELICE_APP_HELPCENTER_URL || '',
  adminUrl: process.env.NEXT_PUBLIC_ELICE_APP_ADMIN_URL || '',
  fontLandingUrl: process.env.NEXT_PUBLIC_ELICE_APP_FONT_LANDING_URL || '',
  schoolUrl: process.env.NEXT_PUBLIC_ELICE_APP_SCHOOL_URL || '',
  schoolLmsUrl: process.env.NEXT_PUBLIC_ELICE_APP_SCHOOL_LMS_URL || '',
};

const eliceServer = {
  apiServerUrl: process.env.NEXT_PUBLIC_ELICE_API_SERVER_URL || '',
  fdCdnFont: process.env.NEXT_PUBLIC_ELICE_FD_CDN_FONT || '',
};

const firebase = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '',
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || '',
  authEmail: process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMAIL || '',
  authPassword: process.env.NEXT_PUBLIC_FIREBASE_AUTH_PASSWORD || '',
};

const service = {
  channelIOPluginKey:
    process.env.NEXT_PUBLIC_CREDENTIALS_CHANNEL_IO_PLUGIN_KEY || '',
  slackWebhookUrl: process.env.NEXT_PUBLIC_SLACK_WEBHOOK_URL || '',
};

const sentry = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '',
};

const analytics = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
  facebookPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '',
};

export { eliceApp, eliceServer, firebase, service, sentry, analytics };
