import axios from 'axios';
import { eliceApp } from 'configs';
import { camelizeKeys } from 'humps';

export function init() {
  axios.defaults.baseURL = eliceApp.schoolUrl;

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      response.data = camelizeKeys(response.data);

      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
}
