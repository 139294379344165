import React from 'react';
import flatten from 'flat';
import * as English from 'i18n/locales/en';
import * as Korean from 'i18n/locales/ko';
import { useRouter } from 'next/router';

export const getFlattenedMessage = (msg: { [key: string]: any }) => {
  return flatten<typeof msg, Record<string, string>>(msg);
};

const useAppLocale = () => {
  const router = useRouter();

  const [currentLocale, setCurrentLocale] = React.useState<string>('ko');
  const [localeMessages, setLocaleMessages] = React.useState<
    Record<string, string>
  >(getFlattenedMessage(Korean));

  const changeLocale = (locale: string) => {
    void router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      {
        locale,
        scroll: false,
      }
    );
  };

  React.useEffect(() => {
    setCurrentLocale(router.locale ?? 'ko');
  }, [router]);

  React.useEffect(() => {
    let flattenedMessage;
    switch (currentLocale) {
      case 'en':
        flattenedMessage = getFlattenedMessage(English);
        break;

      case 'ko':
      default:
        flattenedMessage = getFlattenedMessage(Korean);
        break;
    }

    setLocaleMessages(flattenedMessage);
  }, [currentLocale]);

  return { currentLocale, messages: localeMessages, changeLocale };
};

export default useAppLocale;
