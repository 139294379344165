//eslint-disable-next-line
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import { ReactChannelIO } from 'react-channel-plugin';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { service } from 'configs';
import { init as initAxios } from 'lib/axios';
import { MediaQueryProvider } from 'lib/media-query';
import NextNprogress from 'nextjs-progressbar';
import smoothscroll from 'smoothscroll-polyfill';
import * as gtm from 'lib/gtm';
import { ThemeProvider } from '@mui/material/styles';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

// elice-markdown
// https://git.elicer.io/elice/frontend/library/elice-markdown/-/tree/master/packages/elice-markdown
import 'highlight.js/styles/atom-one-dark.css';
import 'katex/dist/katex.css';

import '../styles/globals.css';
import '../styles/fonts.css';

import useAppLocale from 'i18n/useAppLocale';
import { useRouter } from 'next/router';
import { analytics } from 'configs';
import theme from 'styles/themes';
import { PRIMARY_MAIN_COLOR } from 'styles/themes/palette';

// Init axios
initAxios();

type MyAppProps = AppProps & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: NextPage & {
    getLayout?: (page: React.ReactNode) => React.ReactNode;
  };
};

function MyApp({ Component, pageProps }: MyAppProps) {
  const { currentLocale, messages } = useAppLocale();
  const router = useRouter();

  React.useEffect(() => {
    // this method access window variable.
    // use this polyfill in useEffect which ensure that window varialbe exists
    smoothscroll.polyfill();
  }, []);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      router.events.on('routeChangeComplete', gtm.GTMPageView);
      return () => {
        router.events.off('routeChangeComplete', gtm.GTMPageView);
      };
    }
  }, [router.events]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      import('react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.init(analytics.facebookPixelId || '');
          ReactPixel.pageView();

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        })
        .catch(err => {
          console.error('failed to config facebook pixel', err);
        });
    }
  }, [router.events]);

  // per-page layout
  // https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const renderComponent = () => {
    const getComponent = () => <Component {...pageProps} />;

    if (Component.getLayout) {
      return Component.getLayout(getComponent());
    }

    return (
      <>
        <NextNprogress
          color={PRIMARY_MAIN_COLOR}
          startPosition={0.3}
          stopDelayMs={100}
          height={2}
          showOnShallow={false}
          options={{
            showSpinner: false,
          }}
        />
        {getComponent()}
      </>
    );
  };

  return (
    <ReactChannelIO
      pluginKey={service.channelIOPluginKey || ''}
      language={'ko'}
      autoBoot
      autoBootTimeout={2000}
    >
      <MediaQueryProvider>
        <IntlProvider
          locale={currentLocale}
          messages={messages}
          onError={e => {
            // https://github.com/formatjs/formatjs/issues/1776
            // https://formatjs.io/docs/runtime-requirements#full-icu
            if (e.code === ReactIntlErrorCode.MISSING_DATA) {
              return;
            }
            console.error(e);
          }}
        >
          <ThemeProvider theme={theme}>{renderComponent()}</ThemeProvider>
        </IntlProvider>
      </MediaQueryProvider>
    </ReactChannelIO>
  );
}

export default MyApp;
