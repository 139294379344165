import { createTheme } from '@mui/material';

import type { ThemeOptions } from '@mui/material';

// Typography Spec: https://zeroheight.com/28e7b9d96/p/775b5d-typography/t/7474d3
// Ref: https://mui.com/material-ui/customization/typography/#responsive-font-sizes

const theme = createTheme();

export const typography: ThemeOptions['typography'] = {
  fontFamily: [
    // main font
    'Pretendard',
    // https://mui.com/material-ui/customization/typography/#font-family
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontSize: '3.5rem', // 56px
    lineHeight: 1.18,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.2,
      fontSize: '2rem', // 32px
    },
  },
  h2: {
    fontSize: '3rem', // 48px
    lineHeight: 1.2,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem', // 28px
      lineHeight: 1.27,
    },
  },
  h3: {
    fontSize: '2.25rem', // 36px
    lineHeight: 1.2,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.625rem', // 26px
      lineHeight: 1.32,
    },
  },
  h4: {
    fontSize: '2rem', // 32px
    lineHeight: 1.24,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.32,
    },
  },
  h5: {
    fontSize: '1.75rem', // 28px
    lineHeight: 1.28,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem', // 22px
      lineHeight: 1.28,
    },
  },
  h6: {
    fontSize: '1.5rem', // 24px
    lineHeight: 1.24,
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem', // 20px
      lineHeight: 1.3,
    },
  },
  subtitle1: {
    fontSize: '1.25rem', // 20px
    lineHeight: 1.4,
    fontWeight: 700,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem', // 18px
      lineHeight: 1.3,
    },
  },
  subtitle2: {
    fontSize: '1.125rem', // 18px
    lineHeight: 1.32,
    fontWeight: 700,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem', // 16px
      lineHeight: 1.3,
    },
  },
  body1: {
    fontSize: '1rem', // 16px
    lineHeight: 1.48,
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.3,
    },
  },
  body2: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem', // 13px
      lineHeight: 1.3,
    },
  },
  caption: {
    fontSize: '0.75rem', // 12px
    lineHeight: 1.46,
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6875rem', // 11px
      lineHeight: 1.3,
    },
  },
};
