const common = {
  logout: 'Logout',
  login: 'Login',
  signup: 'Signup',
  support: 'Support',
  schoolLmsLink: 'Start to study',
  oneFreeClass: '1 Free Class',
  header: {
    introduction: 'Introduction',
    classroom: 'Curriculum',
    tutor: 'Tutor',
  },
  languages: {
    cpp: 'C++',
    python: 'Python',
  },
  applyFreeTrial: 'Apply For Free Trial',
};

export default common;
