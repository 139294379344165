const common = {
  logout: '로그아웃',
  login: '로그인',
  signup: '회원가입',
  support: '고객센터',
  schoolLmsLink: '학습하기',
  oneFreeClass: '무료체험',
  header: {
    introduction: '소개',
    classroom: '커리큘럼',
    tutor: '튜터',
  },
  languages: {
    cpp: 'C++',
    python: '파이썬',
  },
  applyFreeTrial: '무료체험 신청',
};

export default common;
