import { alpha } from '@mui/material';

import { Colors } from '../colors';

import type { ThemeOptions } from '@mui/material';

export const PRIMARY_MAIN_COLOR = '#0033FF';
const PRIMARY_DARK_COLOR = '#1b3ab2';
const PRIMARY_LIGHT_COLOR = '#708cfc';

export const lightPalette: ThemeOptions['palette'] = {
  mode: 'light',
  background: {
    default: Colors.white,
  },
  text: {
    primary: Colors.blueGray8,
    secondary: Colors.blueGray7,
    disabled: alpha(Colors.blueGray8, 0.38),
  },
  primary: {
    main: PRIMARY_MAIN_COLOR,
    dark: PRIMARY_DARK_COLOR,
    light: PRIMARY_LIGHT_COLOR,
    contrastText: Colors.white,
  },
  secondary: {
    main: Colors.blueGray6,
    dark: Colors.blueGray7,
    light: Colors.blueGray5,
    contrastText: Colors.white,
  },
  error: {
    main: Colors.red7,
    dark: Colors.red8,
    light: Colors.red4,
    contrastText: Colors.white,
  },
  info: {
    main: Colors.lightBlue7,
    dark: Colors.lightBlue9,
    light: Colors.lightBlue5,
    contrastText: Colors.white,
  },
  success: {
    main: Colors.green8,
    dark: Colors.green9,
    light: Colors.green5,
    contrastText: Colors.white,
  },
  action: {
    hover: alpha(Colors.black, 0.04),
    selected: alpha(Colors.black, 0.08),
    disabledBackground: alpha(Colors.black, 0.12),
    focus: alpha(Colors.black, 0.12),
    disabled: alpha(Colors.black, 0.26),
    active: alpha(Colors.black, 0.54),
  },
  warning: {
    main: Colors.orange8,
    dark: Colors.orange9,
    light: Colors.orange5,
    contrastText: Colors.white,
  },
};

export const darkPalette: ThemeOptions['palette'] = {
  mode: 'dark',
  background: {
    default: '#121212',
  },
  text: {
    primary: Colors.white,
    secondary: alpha(Colors.white, 0.7),
    disabled: alpha(Colors.white, 0.5),
  },
  primary: {
    main: Colors.elicePurple4,
    dark: Colors.elicePurple5,
    light: Colors.elicePurple2,
    contrastText: Colors.black,
  },
  secondary: {
    main: Colors.blueGray4,
    dark: Colors.blueGray5,
    light: Colors.blueGray3,
    contrastText: Colors.black,
  },
  error: {
    main: Colors.red5,
    dark: Colors.red7,
    light: Colors.red3,
    contrastText: Colors.white,
  },
  info: {
    main: Colors.lightBlue4,
    dark: Colors.lightBlue7,
    light: Colors.lightBlue3,
    contrastText: Colors.white,
  },
  success: {
    main: Colors.green4,
    dark: Colors.green7,
    light: Colors.green3,
    contrastText: Colors.white,
  },
  action: {
    hover: alpha(Colors.white, 0.08),
    selected: alpha(Colors.white, 0.16),
    disabledBackground: alpha(Colors.white, 0.12),
    focus: alpha(Colors.white, 0.12),
    disabled: alpha(Colors.white, 0.3),
    active: alpha(Colors.white, 0.56),
  },
  warning: {
    main: Colors.orange4,
    dark: Colors.orange7,
    light: Colors.orange3,
    contrastText: Colors.white,
  },
};
