import { createTheme } from '@mui/material/styles';

import { components } from './components';
import { lightPalette as palette } from './palette';
import { typography } from './typography';

/**
 * Default Mui BreakPoint
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 900px
 * lg, large: 1200px
 * xl, extra-large: 1536px
 */
const theme = createTheme({
  palette,
  components,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1096, // customized
      xl: 1536,
    },
  },
  zIndex: {
    appBar: 970,
  },
});

export default theme;
