const freeTrial = {
  form: {
    noSearchResult: '검색 결과가 없습니다.',
    subject: {
      title: '체험 희망하시는 과목명을 알려주세요.',
      label: {
        scratch: '스크래치',
        python: '파이썬',
      },
    },
    accessPath: {
      title: '무료체험 신청 경로는 어떻게 되시나요?',
      label: {
        acquaintance: '지인 추천',
        classting: '클래스팅',
        iAmSchool: '아이엠스쿨',
        facebook: '페이스북',
        instagram: '인스타그램',
        webSearch: '인터넷 검색',
        naverCafe: '네이버 카페',
        karrotMarket: '당근마켓',
        brochure: '홍보 브로셔',
        onlineCommunity: '온라인 커뮤니티 (카페, 블로그 등)',
      },
    },
    address: {
      label: '거주지',
      title: '거주 중인 지역을 입력해주세요.',
      seoul: '서울특별시',
      busan: '부산광역시',
      daegu: '대구광역시',
      incheon: '인천광역시',
      gwangju: '광주광역시',
      daejeon: '대전광역시',
      ulsan: '울산광역시',
      sejong: '세종특별자치시',
      gyeonggido: '경기도',
      gangwondo: '강원도',
      chungcheongbukdo: '충청북도',
      chungcheongnamdo: '충청남도',
      jeollabukdo: '전라북도',
      jeollanamdo: '전라남도',
      gyeongsangbukdo: '경상북도',
      gyeongsangnamdo: '경상남도',
      jeju: '제주특별자치도',
      overseas: '해외거주',
    },
    checkboxGroup: {
      errorMessage: '하나 이상을 선택해주세요.',
    },
    datetime: {
      label: {
        rank: '{rank}순위',
      },
      title: '체험수업이 가능한 날짜와 시간을 선택해주세요.',
      description:
        '월 – 일 09:00~22:00 사이에 가능하며, 체험 수업은 1시간동안 진행됩니다.\n학생분의 수준과 목표에 가장 적합한 튜터님과의 원할한 매칭을 위하여 신청일 기준 최소 2 영업일 이후로 여유롭게 선택해 주세요 (예시: 오늘이 금요일일 경우 차주 화요일 이후).',
      placeholder: '날짜/시간',
      requiredError: '날짜와 시간을 입력해주세요',
      timeError: '시간을 입력해주세요',
    },
    etc: {
      label: '기타 (직접 입력)',
      placeholder: '30자 이내로 입력해주세요.',
    },
    studentGrade: {
      label: '학년',
      title: '학생의 학년을 선택해주세요',
      grade1: '초 1',
      grade2: '초 2',
      grade3: '초 3',
      grade4: '초 4',
      grade5: '초 5',
      grade6: '초 6',
      middleSchool: '중학생',
      highSchool: '고등학생',
      errorMessage: '학생의 학년을 선택해주세요',
    },
    parentTel: {
      errorMessage: {
        pattern: '잘못된 전화번호입니다.',
        required: '연락처를 입력해주세요',
      },
      label: '학부모님의 연락처를 입력해주세요.',
    },
    pastExperience: {
      title: '학생의 코딩학습 경험을 선택해주세요.',
      label: {
        never: '코딩을 전혀 해본적이 없다.',
        blockCodingOnly: '엔트리, 스크래치 등 블록코딩 경험이 있다.',
        pythonBasic: '파이썬의 기초 문법을 다룰 줄 안다.',
        pythonAndOthersLanguages:
          '파이썬 외 Java, C++등 다른 언어도 할 줄 안다.',
      },
    },
    privateInfo: {
      description:
        '작성된 개인 정보는 무료체험 진행을 위해 사용되며 제3자 제공 또는\n다른 용도로 일절 사용하지 않습니다.',
      label: '개인정보 활용 동의',
    },
    studentAge: {
      errorMessage: '나이를 선택해주세요',
      label: '나이',
    },
    studentGender: {
      man: '남자',
      woman: '여자',
      label: '성별',
      title: '학생의 성별을 선택해주세요',
      errorMessage: '학생의 성별을 선택해주세요',
    },
    studentName: {
      errorMessage: '이름을 입력해주세요',
      title: '학생의 이름을 입력해주세요',
    },
    submit: '무료 체험 신청',
    target: {
      title: '학생의 코딩학습 목표를 선택해주세요.',
      label: {
        essentialKnowledge: '4차 산업혁명에 따른 필수 소양이기 때문에',
        peopleAroundMeDoIt: '주변에서 많이 하니까 뒤쳐지지 않으려고',
        checkTalent: '아이가 재능이 있는지 확인하기 위해',
        interestedInComputer: '아이가 컴퓨터를 좋아하고 재미있어해서',
        codingCompetition:
          '코딩 경시대회를 목표로 하기 때문에 (SW 특기자 전형, 영재원)',
      },
    },

    title: '1:1 튜터링 체험수업 신청하기',

    description: {
      main: '무료체험 수업에서 학생의 권장 나이는{v1}입니다.\n본 신청서를 제출해 주시면 담당 매니저가 빠르게 확인해 {v2} 이내로 연락드리겠습니다.',
      aboveAge: '9세 이상',
      businessDays: '3 영업일',
    },
    countryCode: {
      label: '국가 코드 선택',
    },
  },
  submit: {
    fail: '무료체험 신청에 실패했습니다. 다시 시도해주세요',
    success: '무료체험 신청을 완료했습니다.',
  },
  submission: {
    description:
      '체험수업 진행을 위해 영업일 기준 3일 내에\n담당자가 카톡으로 연락드리겠습니다.\n\n신청해주셔서 감사합니다.',
    find: '다양한 수업 찾아보기',
    title: '무료체험 수업 신청 완료',
  },
};

export default freeTrial;
