const freeTrial = {
  form: {
    noSearchResult: 'No Search Result',
    subject: {
      title: 'Tell us the name of the subject you would like to experience.',
      label: {
        scratch: 'Scratch',
        python: 'Python',
      },
    },
    accessPath: {
      title: 'How did you get the free trial request?',
      label: {
        acquaintance: 'Acquaintance Recommendation',
        classting: 'Classting',
        iAmSchool: 'IAMSchool',
        facebook: 'Facebook',
        instagram: 'Instagram',
        webSearch: 'Web Search',
        naverCafe: 'Naver Cafe',
        karrotMarket: 'Karrot Market',
        brochure: 'Brochure',
        onlineCommunity: 'OnlineCommunity',
      },
    },
    address: {
      label: 'address',
      title: 'Select the address.',
      seoul: 'Seoul',
      Busan: 'Busan',
      Daegu: 'Daegu',
      incheon: 'Incheon',
      gwangju: 'Gwangju',
      daejeon: 'Daejeon',
      ulsan: 'Ulsan',
      sejong: 'Sejong',
      gyeonggido: 'Gyeonggi-do',
      gangwondo: 'Gangwon-do',
      chungcheongbukdo: 'Chungcheongbuk-do',
      chungcheongnamdo: 'Chungcheongnam-do',
      jeollabukdo: 'Jeollabuk-do',
      jeollanamdo: 'Jeollanam-do',
      gyeongsangbukdo: 'Gyeongsangbuk-do',
      gyeongsangnamdo: 'Gyeongsangnam-do',
      Jeju: 'Jeju',
      overseas: 'Overseas',
    },
    checkboxGroup: {
      errorMessage: 'Please select one or more.',
    },
    datetime: {
      label: {
        rank: '{rank} choice',
      },
      title: 'Please choose the date and time that the free trial is possible.',
      description: `It is available from 09:00 to 22:00 Monday-Sunday, and the trial-class lasts for 1 hour.\nFor smooth matching with the tutor most suitable for the student's level and goals, please select at least two business days from the date of application date (e.g., if today is Friday, after the next Tuesday)`,
      placeholder: 'datetime',
      requiredError: 'please enter datetime',
      timeError: 'Please enter time',
    },
    etc: { label: 'Etc', placeholder: 'Please enter within 30 characters.' },
    gender: {
      label: 'gender',
    },
    genderAge: {
      title: 'Select Gender / Age',
    },
    parentTel: {
      errorMessage: {
        pattern: 'Invalid phone number.',
        required: 'Please enter phone number.',
      },
      label: 'Enter the phone number of parent',
    },
    pastExperience: {
      title: "Please select a student's coding experience.",
      label: {
        never: 'Have never done coding at all.',
        blockCodingOnly:
          'Have experience to block coding such as entry, scratch',
        pythonBasic: 'Know basic grammar of python',
        pythonAndOthersLanguages:
          'Know not only python, but also other languages such as Java, C++.',
      },
    },
    privateInfo: {
      description:
        'Personal information is only used for free trial progress and is not used for third parties or other purposes.',
      label: 'Consent with using personal information.',
    },
    studentAge: {
      errorMessage: 'Please select age of student',
      label: 'age',
    },
    studentGrade: {
      label: 'Grade',
      title: 'Enter student grade',
      grade1: 'Elementary school grade 1',
      grade2: 'Elementary school grade 2',
      grade3: 'Elementary school grade 3',
      grade4: 'Elementary school grade 4',
      grade5: 'Elementary school grade 5',
      grade6: 'Elementary school grade 6',
      middleSchool: 'Middle school',
      highSchool: 'High school',
      errorMessage: 'Enter student grade',
    },
    studentGender: {
      man: 'man',
      woman: 'woman',
      label: 'Gender',
      title: 'Please select gender of student',
      errorMessage: 'Please select gender of student',
    },
    studentName: {
      errorMessage: 'Please enter name of student',
      title: 'Enter name of student.',
    },
    submit: 'Submit free trial form',
    target: {
      title: "Select the goal of student's coding learning.",
      label: {
        essentialKnowledge: 'It is essential for the 4th Industrial Revolution',
        peopleAroundMeDoIt: 'Do not want to go back around',
        checkTalent: 'Check that child has talent',
        interestedInComputer: 'Child likes computers',
        codingCompetition: 'For coding competion',
      },
    },
    title: 'Apply for 1:1 tutoring experience class',
    description: {
      main: 'Age of {v1} are recommended.\nAfter submitting this application, our manager will quickly verify and contact you within {v2}.',
      aboveAge: '10 years old or above',
      businessDays: '3 business days',
    },
    countryCode: {
      label: 'Select Country Code',
    },
  },
  submit: {
    fail: 'Fail to submit free trial application. Please try again',
    success: 'Success to submit a free trial application.',
  },
  submission: {
    description:
      'In order to proceed with the experiential class, the person in charge will be in contact within the 3rd business day.',
    find: 'Find other programs',
    title: 'Free trial form submit success',
  },
};

export default freeTrial;
